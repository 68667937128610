import React from 'react';
import { Link } from 'gatsby'


class BpostOut extends React.Component {

  render() {
     const posts = this.props.posts
     ////console.log(posts.slice(0,1))
  return (
    <>
    {posts.map(({ node }) => {
      const title = node.frontmatter.title || node.fields.slug
      return (
        <div key={title} className="box" to={`/${node.fields.slug}`} style={{margin:'3%',minWidth:'300px',width:'44%',backgroundColor:'#f6f6f6',padding:'20px',border:'1px solid #f3f3f3',borderRadius:'10px'}}>
        <Link to={`/${node.fields.slug}`}>
          <img className="blog-img" style={{borderRadius:'0px 5px',width:'100%'}} alt={node.frontmatter.title} loading="lazy" src={node.frontmatter.image} />
        </Link>
        <div key={node.fields.slug} >
          <h3 className="is-size-4" style={{fontWeight:'600'}}
          >
            <Link style={{ boxShadow: `none` }} to={`/${node.fields.slug}`}>
              {title}
            </Link>
            <br />
            <sub>
            {node.frontmatter.date}
            </sub>
          </h3>
          <br />
          <p
            dangerouslySetInnerHTML={{
              __html: node.excerpt,
            }}
          /><br />
          <Link to={`/${node.fields.slug}`} className="button rexer-button is-size-7">przeczytaj więcej...</Link>
        </div>
        </div>
      )
    })}
    </>
  )
}
}

export default BpostOut
