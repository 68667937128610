import React from "react";
import { Link, graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Newsletter from "../components/newsletter.js";
import CTA from "../components/cta.js";
import Bio from "../components/bio";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { gsap } from "gsap";
import Share from "../components/Share";
import { rhythm, scale } from "../utils/typography";
import BpostOut from "../components/bpostOut";

class BlogPostTemplate extends React.Component {
  componentDidMount() {
    gsap.to("#a1", { y: -500, duration: 0 });
    gsap.to("#a1", { y: 0, duration: 3 });
  }

  render() {
    const post = this.props.data.mdx;
    const siteTitle = this.props.data.site.siteMetadata.title;
    let posts = this.props.data.allMdx.edges;

    let filterPosts = [];

    posts.forEach((item, i) => {
      if (item.node.frontmatter.title !== post.frontmatter.title) {
        filterPosts.push(item);
      }
    });

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <div id="blog-pad" style={{ padding: "3em" }}>
          <div style={{ padding: "2.5% 10%" }}>
            <img
              id="a1"
              src={post.frontmatter.image}
              alt={post.frontmatter.title}
              width="100%"
              style={{ borderRadius: "0px 5px" }}
            />
            <h1
              style={{
                borderLeft: "5px solid #00ff00",
                fontWeight: "600",
                textTransform: "uppercase",
                paddingLeft: "10px",
                marginLeft: "0px",
                fontSize: "2.5em"
              }}
            >
              {post.frontmatter.title}
            </h1>
            <h2 className="subtitle">{post.frontmatter.description}</h2>
            <span
              style={{
                ...scale(-1 / 5),
                padding: "5px 10px",
                marginBottom: rhythm(1),
                backgroundColor: "#111",
                color: "#00ff00",
                boxShadow: "5px 10px #00ff00"
              }}
            >
              {post.frontmatter.date}
            </span>
          </div>
          <div id="render" style={{ padding: "2.5% 10%" }}>
            <MDXRenderer>{post.body}</MDXRenderer>
            <br />
            <hr
              style={{
                marginBottom: rhythm(1)
              }}
            />
            {/*<Bio />*/}
            <br />
            <br />
            <Share
              title={post.frontmatter.title}
              slug={post.slug}
              excerpt={post.frontmatter.description}
              siteUrl="https://rexer.pl"
              pathPrefix="/aktualnosci/"
            />
          </div>

          <div style={{ textAlign: "center" }}>
            <Link className="rexer-button button is-size-4" to="/aktualnosci/">
              Aktualności
            </Link>
          </div>
        </div>

        <div style={{ padding: "5%", backgroundColor: "#00ff00" }}>
          <h2 className="is-size-4 title">Pozostałe aktualności:</h2>
          <div
            id="scroll-wrapper"
            style={{
              overflowX: "scroll",
              display: "flex",
              flexWrap: "no-wrap"
            }}
          >
            <BpostOut posts={filterPosts} />
          </div>
        </div>
        <Newsletter />
        <CTA />
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const bpageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      slug
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        templateKey
        date(formatString: "DD. MM. YYYY")
        language
        description
        image
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          language: { eq: "Polish" }
          templateKey: { eq: "blog-page" }
        }
      }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD. MM. YYYY")
            title
            language
            image
            category
            description
            templateKey
          }
        }
      }
    }
  }
`;
